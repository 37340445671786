#home {
    background-color: black;
    color: white;
    height: 100vh;

    // .logo img {
    //     max-height: 100px;
    // }
    // #heartsBackground {
    //     background-image: url(/heartsBackgd.jpg);
    //     padding-bottom: 50px;
    // }
    // nav {
    //     background-color: #3e276674;
    //     padding-left: 2.5em;
    //     padding-right: 2.5em;
    // }

    // .btn {
    //     background-color: #ee2e31;
    //     border-color: #ee2e31;
    // }
    // nav .btn {
    //     height: 50%;
    // }

    // .intro {
    //     margin-top: 40px;
    //     margin-bottom: 40px;
    // }
    // .topRow {
    //     margin-top: 40px;
    // }
    // .coverPhoto img {
    //     object-fit: cover;
    // }
    // input,
    // .input-group label,
    // select {
    //     margin: 20px auto;
    // }
    // .ratingsNumber {
    //     display: inline !important;
    // }
}
